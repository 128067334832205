import React, { useState, useEffect } from "react";
import axios from "axios";
import MessageComponent from "../message/MessageComponent";

const UpdateLandingPagePictureForm = () => {
  const [selectedId, setSelectedId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [ids, setIds] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchIds();
  }, []);

  const fetchIds = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/LandingPagePicture`;
      const response = await axios.get(apiUrl);
      setIds(response.data.map(({ id }) => id)); // Assuming API returns array of objects with an 'id' field
    } catch (error) {
      setMessage({ type: "error", text: `Failed to fetch records. ${error}` });
    }
  };

  // Create a mapping between ID and Landing Page Names
  const idToPageName = {
    1: "Home Landing Image",
    2: "Foundation Landing Image",
    3: "About Us Landing Image",
    4: "Galleries Landing Image",
    5: "Services Landing Image",
    6: "Donate Landing Image",
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedId) {
      setMessage({ type: "error", text: "Please select a Landing Page" });
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const api = `${process.env.REACT_APP_API_URL}api/update-LandingPage-picture/${selectedId}`;
      await axios.patch(api, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage({ type: "success", text: "Image updated successfully!" });
    } catch (error) {
      setMessage({ type: "error", text: `Error updating image: ${error}` });
    }
  };

  return (
    <div className="Admin-form" style={{ height: "fit-content" }}>
      <h2 className="tit__1">Update Landing Page Picture</h2>
      <p className="Expensivemessage ExpensiveIcon">
        <strong className="ExpensiveIcon"> + </strong>Landing Page Image updater
        by page name
      </p>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon"> + </strong> If you leave this title
        field empty,{" "}
        <u className="black">the default value will be displayed.</u>
      </p>

      <form onSubmit={handleSubmit}>
        <div className="Adminflex">
          <label htmlFor="id">
            <select
              className="input"
              value={selectedId}
              onChange={(e) => setSelectedId(e.target.value)}
            >
              <option value="">Select Landing Page Record</option>
              {ids && ids.map((id) => (
                <option key={id} value={id}>
                  {idToPageName[id] || `Page ${id}`}
                </option>
              ))}
            </select>
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="file"
              name="image"
              onChange={handleFileChange}
            />
          </label>
        </div>
        <br />

        <div style={{ display: "grid", justifySelf: "end" }}>
          <button className="bookmarkBtn buttonUp">
            <span className="IconContainer">
              <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
              </svg>
            </span>
            <p className="text">Upload</p>
          </button>
        </div>
      </form>

      {message && <MessageComponent type={message.type} text={message.text} />}
    </div>
  );
};

export default UpdateLandingPagePictureForm;
