import React, { useState, useEffect } from 'react';
import Sidebar from './global/Sidebar';
import TopBar from './global/TopBar';
import Footer from '../components/footer/Footer';
import LandingPagePicturesComponent from './adminComponents/homepage/UploadLandingPagePictures';
import UpdateLandingPagePictureFormComponent from './adminComponents/homepage/UpdateLandingPagePictureForm';
import axios from 'axios';

const Admin = () => {
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/LandingPagePicture`;
        const response = await axios.get(apiUrl);
        setDataLength(response.data.length);
        console.log('Data length:', response.data.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: 'fit-content' }}>
        <div className="sidebar" style={{ backgroundColor: '#21232d', height: '100%' }}>
          <Sidebar />
        </div>

        <div className="adminpageS container Admincenter">
          <div className="grid-horizontal">
            {/* Conditionally hide the component if dataLength is greater than or equal to 6 */}
            <div style={{ display: dataLength > 6 ? 'none' : 'block' }}>
              <LandingPagePicturesComponent dataLength={dataLength} />
            </div>
            <UpdateLandingPagePictureFormComponent />
          </div>
        </div>
      </div>
      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default Admin;
