import { useContext, createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";  // Correct import

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem("refreshToken") || "");
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const loginAction = async (data) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/Adminlogin`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.success && res.data.token) {
        setUser(res.data.username);
        setToken(res.data.token);
        setRefreshToken(res.data.refreshToken);
        localStorage.setItem("site", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        navigate("/AdminPage");
        return;
      }
      throw new Error(res.message || "Login failed");
    } catch (err) {
      setError("Please try again.");
    }
  };

  const logOut = useCallback(() => {
    setUser(null);
    setToken("");
    setRefreshToken("");
    localStorage.removeItem("site");
    localStorage.removeItem("refreshToken");
    navigate("/login");
  }, [navigate]);

  const refreshAccessToken = useCallback(async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/refresh-token`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken }),
      });
      const res = await response.json();
      if (res.success && res.token) {
        setToken(res.token);
        localStorage.setItem("site", res.token);
        return;
      }
      throw new Error(res.message || "Token refresh failed");
    } catch (err) {
      setError("Please log in again.");
      logOut();
    }
  }, [refreshToken, logOut]);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (token) {
        refreshAccessToken();
      }
    };

    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keypress", handleUserInteraction);

    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keypress", handleUserInteraction);
    };
  }, [token, refreshAccessToken]);

  useEffect(() => {
    if (token) {
      const tokenExpirationTime = jwtDecode(token).exp * 1000;
      const refreshTokenBefore = tokenExpirationTime - 60000; // 1 minute before expiration

      const timeoutId = setTimeout(() => {
        refreshAccessToken();
      }, refreshTokenBefore - Date.now());

      return () => clearTimeout(timeoutId);
    }
  }, [token, refreshAccessToken]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        const tokenExpirationTime = jwtDecode(token).exp * 1000;
        if (Date.now() >= tokenExpirationTime) {
          logOut();
        }
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 1000);

    return () => clearInterval(intervalId);
  }, [token, logOut]);

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {error && (
        <div className="LoginContainer container red errorbottom">
          Error: {error}
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
